<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-md-4 col-lg-3 bg-light">
        <h3 class="text-primary text-center border-bottom border-primary">
          Travelling address
        </h3>
        <div class="row g-1">
          <div class="col-6  py-2 ">
            <input
              id="rdOneway"
              type="radio"
              class="btn-check"
              autocomplete="off"
              name="tripDirectionType"
              checked="checked"
            >
            <label
              class="btn btn-outline-dark btn-sm  w-100 fs-5"
              for="rdOneway"
            ><i class="fas fa-route" /> Oneway trip</label>
          </div>
          <div class="col-6   py-2">
            <input
              id="rdRound"
              type="radio"
              class="btn-check"
              autocomplete="off"
              name="tripDirectionType"
            >
            <label
              class="btn btn-outline-dark  btn-sm w-100 fs-5"
              for="rdRound"
            ><i class="fas fa-spinner" /> Round trip</label>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-lg-9">
        <h3 class="text-secondary text-center border-bottom border-secondary">
          Your  address
        </h3>
      </div>
    </div>
    <div class="row g-1">
      <div class="col-md-12">
        <h3 class="text-secondary text-center border-bottom border-secondary">
          Service Information
        </h3>
      </div>
      <div class="col-md-4   py-2 ">
        <input
          id="cbPeople"
          type="checkbox"
          class="btn-check"
          autocomplete="off"
        >
        <label
          class="btn btn-people w-100 fs-3"
          for="cbPeople"
        >People</label>
      </div>
      <div class="col-md-4   py-2">
        <input
          id="cbPackage"
          type="checkbox"
          class="btn-check"
          autocomplete="off"
        >
        <label
          class="btn btn-package  w-100 h-100 fs-3"
          for="cbPackage"
        ><i class="fas fa-toggle-on " />Package</label>
      </div>
      <div class="col-md-4  py-2">
        <input
          id="cbProduct"
          type="checkbox"
          class="btn-check"
          autocomplete="off"
        >
        <label
          class="btn btn-product w-100 fs-3"
          for="cbProduct"
        >Product</label>
      </div>
    </div>
  </div>
</template>
<script>
// reference
// http://stage.trepr.local/becometraveler/travelinfo
// http://stage.trepr.local/becometraveler/service
export default {
  name: "AddFirstService"
}
</script>

<style scoped>
input[type="checkbox"]:not(:checked)+label {
  /* Explicit Unchecked Styles */
  background-color: #e6e6e6;
  border: transparent;
  color: rgba(0,0,0,.9);
  background-image: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  opacity: .6;
}
</style>